import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './guards/auth-guard.service';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) }, //component: LoginComponent },
  { path: 'unauthorized', loadChildren: () => import('./unauthorized/unauthorized.module').then(m => m.UnauthorizedModule) }, //component: UnauthorizedComponent },
  { path: 'seguranca', loadChildren: () => import('../seguranca/seguranca.module').then(m => m.SegurancaModule) },
  { path: 'cadastro', loadChildren: () => import('../cadastro/cadastro.module').then(m => m.CadastroModule) },
  { path: 'movimentacao-carreta/:carreta', loadChildren: () => import('../movimentacao-carreta/movimentacao-carreta.module').then(m => m.MovimentacaoCarretaModule)}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MCGRoutingModule { }
