import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Storage } from '../storage';
import { RecursoModel } from '../models/recurso.model';
import { sprintf } from 'sprintf-js';
import { UsuarioModel } from '../models/usuario.model';

@Injectable({ providedIn: 'root' })
export class AutenticacaoService {
  static getRecursoUsuario: any;

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl, private router: Router, private storage: Storage) {

  }

  private apiLogin: string = this.apiUrl + 'login';
  private apiSeguranca: string = this.apiUrl + 'seguranca';
  private chaveToken: string = 'userToken';
  private chaveRecursoUsuario: string = 'userResources';
  private chaveUsuario: string = 'user';
  private chaveHoraValidade: string = 'timeValid';
  private redirectUrl: string = '';
  private chaveRefreshToken: string = 'refreshToken';


  public isLoggedIn() {
    let token = this.getAccessToken();

    //Token inexistente
    if (!token)
      return false;
    else {
      //let usu = this.getUsuarioLogado();
      //if (usu.NomeUsuario)
      //  return true;
      //else {
      //  this.storage.clearValue(this.chaveToken);
      //  this.storage.clearValue(this.chaveRecursoUsuario);
      return true;
      //}
    }
  }

  public setRedirectUrl(url) {
    this.redirectUrl = url;
  }

  public getRedirectUrl() {
    return this.redirectUrl;
  }

  public getUsuarioLogado() {
    return this.storage.getValue<UsuarioModel>(this.chaveUsuario);
  }

  public getUsuario(login: string) {
    return this.http.get<UsuarioModel>(this.apiSeguranca.concat(sprintf('/usuario/%s', login)));
  }

  public getAccessToken() {
    return this.storage.getValue<string>(this.chaveToken);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.chaveRefreshToken);
  }

  public getRecursosUsuario() {
    return this.storage.getValue<RecursoModel[]>(this.chaveRecursoUsuario);
  }

  public getPermissao(recurso: string) {
    let recs = this.storage.getValue<RecursoModel[]>(this.chaveRecursoUsuario);
    if (recs){
      let rec = recs.find(f => f.codigo == recurso)
      return (rec != undefined)
    }
    else
      return false;
  }

  public setTimeValid() {
    let data = new Date();
    data.setHours(data.getHours() + 12);
    this.storage.setValue<Date>(this.chaveHoraValidade, data);
  }

  public getTimeValid() {
    return this.storage.getValue<Date>(this.chaveHoraValidade);
  }

  public verifyRecursoUsuario(login: string) {
    return this.http.get<RecursoModel[]>(this.apiSeguranca.concat(sprintf('/recurso/%s', login)));
  }

  public setRecursosUsuario(permissoes: RecursoModel[]) {
    this.storage.setValue<RecursoModel[]>(this.chaveRecursoUsuario, permissoes);
  }

  public setUsuario(usuario: UsuarioModel) {
    this.storage.setValue<UsuarioModel>(this.chaveUsuario, usuario);
  }

  public validaRecursoUsuario(recurso: string): boolean {
    if (recurso && recurso.trim() != '') {
      let arrRecursos = this.getRecursosUsuario();
      if (arrRecursos.length > 0 && arrRecursos.find(r => r.codigo == recurso)) {
        return true;
      }
    }
    return false;
  }

  public setRefreshToken(refreshToken: string) {
    localStorage.setItem(this.chaveRefreshToken, refreshToken);
  }

  public setAccessToken(token: string) {
    this.storage.setValue<string>(this.chaveToken, token);
  }

  public removeTokenKey() {
    this.storage.clearValue(this.chaveToken);
  }

  public logout() {
    this.storage.clearValue(this.chaveToken);
    this.storage.clearValue(this.chaveRecursoUsuario);
    this.storage.clearValue(this.chaveUsuario);
    this.storage.clearValue(this.chaveRefreshToken);
    this.setRedirectUrl('');
    this.router.navigate(['/login']);
  }

  public autenticaUsuario(usuario: string, senha: string) {
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
    let body = 'username=' + usuario +
      '&password=' + senha +
      '&grant_type=password' +
      '&refresh_token=' + '';
    return this.http.post(this.apiLogin, body, options);
  }

  public renovaTokenUsuario(usuario: string, refreshToken: string) {
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
    let body = 'username=' + usuario +
      '&grant_type=refresh_token' +
      '&refresh_token=' + refreshToken;
    return this.http.post(this.apiLogin, body, options);
  }
}