import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Util } from 'src/app/comuns/util';
import { Router } from '@angular/router';
import { AutenticacaoService } from 'src/app/comuns/services/autenticacao.service';
import { GlobalService } from 'src/app/comuns/services/global.service';

@Component({
  selector: 'mcg-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  title: string;
  showAcoes: boolean
  @Output() toggleMenu = new EventEmitter<boolean>();

  constructor(public util: Util, private route: Router, public authenticationService: AutenticacaoService, public globalService: GlobalService) { }

  ngOnInit() {
    this.title = "MCG - Monitoramento do Circuito de Gusa";

  }

  clickButton() {
    this.toggleMenu.emit(true);
  }

  navigateHome(){
    if (this.globalService.showAcoes()){
      window.setTimeout(() => this.route.navigate([""]), 500);
    } 
  }

  logout() {
    this.globalService.clearMenu();
    this.authenticationService.logout();
  }

  login() {
    this.route.navigate(['/login']);
  }

}
