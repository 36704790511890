<div class="container" [class.is-mobile]="util.isMobile">

  <mcg-header (toggleMenu)="sidenav.toggle()"></mcg-header>

  <mat-sidenav-container class="sidenav-container" [style.marginTop.px]="util.isMobile ? 56 : 0" autosize>

    <mat-sidenav  #sidenav mode="over" class="menu-sidenav" [ngStyle]="{ 'min-width.px': sidenavWidth }" opened=false>

      <mcg-menu-esquerdo (toggleMenu)="sidenav.toggle()"></mcg-menu-esquerdo>

    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
      <div [hidden]="!loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div [hidden]="loading">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
