<dx-popup width="380px" height="202px" [showTitle]="true" title="Exclusão" [dragEnabled]="false" [closeOnOutsideClick]="true" [visible]="showDelete" (onHiding)="showDelete = false" (onShown)="showMessage()">

  <div *dxTemplate="let data of 'content'" fxLayout="column" fxLayoutGap="12px">
    <div fxLayout="column" fxLayoutGap="8px" style="min-height: 70px; padding-top: 12px; padding-left: 12px">
      <div><span [innerHTML]="mensagem"></span></div>
      <span>&nbsp;</span>
    </div>
    <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="end center" style="padding-right: 12px">
      <button mat-stroked-button tabindex="-1" (click)="deletar()">Sim</button>
      <button mat-stroked-button color="primary" tabindex="-1" (click)="cancelar()">Não</button>
    </div>
  </div>
</dx-popup>
