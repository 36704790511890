import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject, throwError, of } from 'rxjs';
import { AutenticacaoService } from 'src/app/comuns/services/autenticacao.service';
import { GlobalService } from 'src/app/comuns/services//global.service';
import { retry, catchError, debounceTime, filter, take, switchMap, finalize } from 'rxjs/operators';
import { Util } from '../util';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private auth: AutenticacaoService, private globalService: GlobalService, private injector: Injector, private router: Router, private util: Util) { }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {

    if (!this.auth.getAccessToken()) {
      return request;
    }

    // se for uma chamada para a api de autenticação
    if (request.url.includes("login")) {
      return request;
    }

    return request.clone({
      setHeaders:
      {
        'Authorization': 'Bearer ' + this.auth.getAccessToken(),
        'Content-Type': 'application/json'
      }
    })
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    request = this.addAuthenticationToken(request);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && error.status === 401) {
          // 401 errors are most likely going to be because we have an expired token that we need to refresh.
          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject.pipe(
              filter(result => result !== null),
              take(1),
              switchMap(() => next.handle(this.addAuthenticationToken(request)))
            );
          }
          else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);
            let user = this.auth.getUsuarioLogado();
            if (!user) {
              this.globalService.clearMenu();
              this.auth.logout();
              return next.handle(request);
            }
            else {
              return this.auth.renovaTokenUsuario(user.login, this.auth.getRefreshToken()).pipe(
                debounceTime(500),
                switchMap((data: any) => {
                  this.auth.setAccessToken(data['access_token']);
                  this.auth.setRefreshToken(data['refresh_token']);
                  this.globalService.setMenu();

                  this.refreshTokenInProgress = false;
                  this.refreshTokenSubject.next(data);

                  return next.handle(this.addAuthenticationToken(request));
                }),
                catchError((err: any) => {
                  this.refreshTokenInProgress = false;

                  if (err.status == 403) {
                    this.globalService.clearMenu();
                    this.auth.logout();
                  }

                  return next.handle(request);
                }),
                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                // for the next time the token needs to be refreshed
                finalize(() => this.refreshTokenInProgress = false)
              );
            }
          }
        }
        else {
          return throwError(error);
        }
      })
    );
  }
}

