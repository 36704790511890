import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Storage {
    public setValue<T>(key: string, value: T) {
        window.localStorage.setItem(key, JSON.stringify(value));
    }

    public getValue<T>(key: string) {
        if (window.localStorage.getItem(key))
            return <T>JSON.parse(window.localStorage.getItem(key));
        else
            return null;
    }

    public clearValue(key: string) {
        window.localStorage.removeItem(key);
    }
}