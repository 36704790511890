import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

//Definindo cultura PT ao devextreme
import { locale, loadMessages } from 'devextreme/localization';
import 'devextreme-intl';

//Carregando mensagens traduzidas
let messagesPt = require("devextreme/localization/messages/pt.json");
registerLocaleData(localePt);
loadMessages(messagesPt);
locale('pt');

// Configurando FontAwesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import {FlexLayoutModule} from '@angular/flex-layout';

import { AppComponent } from './app.component';
import { CcpRootModule } from './mcg-root/mcg-root.module';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    CcpRootModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far, fab);
    }
}
