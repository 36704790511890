import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { GlobalService } from '../../comuns/services/global.service';
import { MenuModel } from 'src/app/comuns/models/menu.model';
import { AutenticacaoService } from 'src/app/comuns/services/autenticacao.service';

@Component({
  selector: 'mcg-menu-esquerdo',
  templateUrl: './menu-esquerdo.component.html',
  styleUrls: ['./menu-esquerdo.component.css']
})
export class MenuEsquerdoComponent implements OnInit {

  @Output() toggleMenu = new EventEmitter<boolean>();

  constructor(public globalService: GlobalService, private route: Router) { }

  ngOnInit() {
    this.globalService.setMenu();

    this.route.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          // if (!this.route.url.toUpperCase().includes("HOME"))
          //   window.setTimeout(() => this.toggleMenu.emit(true), 200);
          // else if (this.route.url.toUpperCase().includes("HOME") && this.authenticationService.isLoggedIn())
          //   window.setTimeout(() => this.toggleMenu.emit(true), 200);
          // else if (this.route.url.toUpperCase().includes("LOGIN") && !this.authenticationService.isLoggedIn())
          //   window.setTimeout(() => this.toggleMenu.emit(true), 200);

          // if (!this.authenticationService.isLoggedIn())
          //   window.setTimeout(() => this.toggleMenu.emit(true), 200);
        }
      });
  }

  selectItem(e) {
    if (e.itemData.rota) {
      window.setTimeout(() => this.route.navigate([e.itemData.rota]), 500);
      window.setTimeout(() => this.toggleMenu.emit(true), 200);
    }
  }

}
