import { Injectable } from '@angular/core';
import { MenuModel } from '../models/menu.model';
import { MesModel } from '../models/mes.model';
import { AutenticacaoService } from './autenticacao.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class GlobalService {
  private _itensMenu: MenuModel[] = new Array<MenuModel>();


  constructor(private authService: AutenticacaoService, private route: Router) {

  }


  get itensMenu(): MenuModel[] {
    return this._itensMenu;

  }

  public setMenu() {
    this._itensMenu = new Array<MenuModel>();


    //SEGURANCA
    this._itensMenu.push(new MenuModel(1, "Segurança", 0, null, null, "fas", "shield-alt", "", null));
    this._itensMenu.push(new MenuModel(2, "Usuários", 1, 1, 1, "fas", "users", "seguranca/usuario", "GESTAO_USUARIO"));
    this._itensMenu.push(new MenuModel(6, "Grupos", 1, 1, 1, "fas", "object-group", "seguranca/grupo", "GESTAO_GRUPO"));

    //DADOS MESTRES
    // this._itensMenu.push(new MenuModel(3, "Datos Maestros", 0, null, null, "fas", "cogs", "", null));
    // this._itensMenu.push(new MenuModel(9, "Material", 1, 3, 3, "fas", "boxes", "cadastro/material", "F214"));


    //PRODUCAO
    // this._itensMenu.push(new MenuModel(4, "Producción", 0, null, null, "fas", "cogs", "", null));

    // this._itensMenu.push(new MenuModel(5, "Horno de Recalentamiento", 1, 4, 4, "fas", "dumpster-fire", "producao/forno-reaquecimento", "F302"));


    //Paradas
    // this._itensMenu.push(new MenuModel(7, "Paradas", 0, null, null, "fas", "stopwatch", "", null));

    // this._itensMenu.push(new MenuModel(8, "Dashboard", 1, 7, 7, "fas", "chart-pie", "parada/dashboard", "F314"));


    //Filtra os itens de menu de acordo com as permissões do usuário
    let recursosUsuario = this.authService.getRecursosUsuario();

    if (recursosUsuario) {
      //Obtém os itens de menu que não tem controle de acesso e os
      //que tem controle de acesso e que o usuário tenha permissão
      this._itensMenu = this._itensMenu.filter(m => m.recurso == null || recursosUsuario.find(f => f.codigo == m.recurso));

      //Remove os itens pai que não são rotas e não tem filhos ativos
      let itemMenuPai = this._itensMenu.filter(m => m.rota == "");
      itemMenuPai.forEach(item => {
        let index = this._itensMenu.findIndex(i => i.idPai === item.id);
        if (index == -1)
          this._itensMenu = this._itensMenu.filter(m => m.id != item.id);
      });
    }
    else
      this.clearMenu();

  }

  public clearMenu() {
    this._itensMenu = new Array<MenuModel>();
  }

  public getMeses(): MesModel[] {
    let meses: MesModel[] =
      [
        { mes: 1, descricao: 'Enero' },
        { mes: 2, descricao: 'Febrero' },
        { mes: 3, descricao: 'Marzo' },
        { mes: 4, descricao: 'Abril' },
        { mes: 5, descricao: 'Mayo' },
        { mes: 6, descricao: 'Junio' },
        { mes: 7, descricao: 'Julio' },
        { mes: 8, descricao: 'Agosto' },
        { mes: 9, descricao: 'Septiembre' },
        { mes: 10, descricao: 'Octubre' },
        { mes: 11, descricao: 'Noviembre' },
        { mes: 12, descricao: 'Diciembre' },
      ];
    return meses;
  }

  public showAcoes() {
    if (this.route.url.indexOf('movimentacao-carreta') < 0)
      return true;
    else
      return false;
  }
}

