import { Component, OnInit, Input } from '@angular/core';
import { Util } from '../../util';
import { Router } from '@angular/router';

@Component({
  selector: 'mcg-barra-ferramentas',
  templateUrl: './barra-ferramentas.component.html',
  styleUrls: ['./barra-ferramentas.component.css']
})
export class BarraFerramentasComponent implements OnInit {

  @Input() titulo: string;
  @Input() faImagem: string;
  @Input() imagem: string;
  @Input() loading: boolean;
  @Input() componente: string;

  constructor(public util: Util, private route: Router) { }

  ngOnInit() {
    
  }

}
