import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Messages } from '../../messages';


@Component({
  selector: 'mcg-confirm-delete',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.css']
})
export class ConfirmDeleteComponent implements OnInit {

  @Input() tipoMensagem: string;
  @Output() delete = new EventEmitter<boolean>();

  @Input() showDelete: boolean = false;
  mensagem: string;
  titleMessage: string;
  constructor(private mensagens: Messages) { }

  ngOnInit() {
  
  }

  showMessage() {
    if (this.tipoMensagem == this.mensagens.DELETE_GROUP)
      this.mensagem = this.mensagens.CONFIRM_DELETE_GROUP;
    else if (this.tipoMensagem == this.mensagens.DELETE_USER_GROUP)
      this.mensagem = this.mensagens.CONFIRM_DELETE_USER_GROUP;
    else if (this.tipoMensagem == this.mensagens.DELETE_RESOURCE_GROUP)
      this.mensagem = this.mensagens.CONFIRM_DELETE_RESOURCE_GROUP;
    else if (this.tipoMensagem == this.mensagens.DELETE_USER)
      this.mensagem = this.mensagens.CONFIRM_DELETE_USER;
  }

  cancelar() {
    this.delete.emit(false);
    this.showDelete = false;
  }

  deletar() {
    this.delete.emit(true);
    this.showDelete = false;
  }

}
