import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Util } from '../comuns/util';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { AutenticacaoService } from '../comuns/services/autenticacao.service';

@Component({
  selector: 'mcg-root',
  templateUrl: './mcg-root.component.html',
  styleUrls: ['./mcg-root.component.css']
})
export class MCGRootComponent implements OnInit, AfterViewInit {

  sidenavWidth: number = 260;
  loading: boolean = false;

  constructor(public util: Util, private router: Router, private authenticationService: AutenticacaoService) {
    this.loading = true;
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.loading = false;
        }
      });
  }

}
