<div fxLayout="row" class="header">
  <div fxFlex="util.isMobile : 90 ? 60" fxLayoutAlign="start center" class="conteudo-titulo">
      <fa-icon *ngIf="imagem" [icon]="[faImagem, imagem]"></fa-icon>
      <span [ngClass]="util.isMobile ? 'titulo-sm' : 'titulo'">{{titulo}}</span>
  </div>
  <div fxFlex="40" fxLayoutAlign="end center" fxLayoutGap="8px" class="conteudo-itens" [ngClass]="{'largura-mobile': util.isMobile}">
    <ng-content></ng-content>
  </div>
</div>
<mcg-barra-loading [loading]="loading"></mcg-barra-loading>
