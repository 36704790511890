export class MenuModel{
    constructor(
      public id?: number,
      public descricao?: string,
      public hieraraquia?: number,
      public idPai?: number,
      public idGrandePai?: number,
      public faImagem?: string,
      public imagem?: string,
      public rota?: string,
      public recurso?: string 
      ) { }
  }
