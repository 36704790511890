import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Messages {

  get SUCESS_ACTION(): string {
    return "Ação executada com sucesso!";
  }

  get CONFIRM_EXECUTION_ACTION(): string {
    return "Confirma a execução dessa ação?";
  }

  get CONFIRM_DELETE_GROUP(): string {
    return "Confirma a exclusão deste grupo?";
  }

  get CONFIRM_DELETE_USER(): string {
    return "Confirma a exclusão deste usuario?";
  }

  get CONFIRM_DELETE_USER_GROUP(): string {
    return "Confirma a exclusão deste usuário deste grupo?";
  }

  get CONFIRM_DELETE_RESOURCE_GROUP(): string {
    return "Confirma a exclusão desta permissão deste grupo?";
  }

  get DELETE_USER(): string {
    return "DELETE_USER";
  }

  get DELETE_GROUP(): string {
    return "DELETE_GROUP";
  }

  get DELETE_USER_GROUP(): string {
    return "DELETE_USER_GROUP";
  }

  get DELETE_RESOURCE_GROUP(): string {
    return "DELETE_RESOURCE_GROUP";
  }

}
