import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularResizedEventModule } from 'angular-resize-event';
import { environment } from 'src/environments/environment';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxTreeViewModule } from 'devextreme-angular';
import { MCGRootComponent } from './mcg-root.component';
import { MCGRoutingModule } from './mcg-routing.module';
import { HeaderComponent } from './header/header.component';
import { MenuEsquerdoComponent } from './menu-esquerdo/menu-esquerdo.component';
import { HttpAuthInterceptor } from '../comuns/interceptor/http-auth-interceptor';
import { AuthGuardService } from './guards/auth-guard.service';
import { MCGComponentsModule } from '../comuns/components/mcg-components.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    MCGRootComponent,
    HeaderComponent,
    MenuEsquerdoComponent
  ],
  imports: [
    // Angular
    CommonModule,
    BrowserModule,
    HttpClientModule,
    FontAwesomeModule,
    FlexLayoutModule,
    AngularResizedEventModule,

    // Material
    MatSidenavModule,
    MatToolbarModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatMenuModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,

    // DevExtreme
    DxTreeViewModule,

    //App
    MCGComponentsModule,
    MCGRoutingModule
  ],
  exports: [
    MCGRootComponent
  ],
  providers: [
    DatePipe,
    DecimalPipe,
    { provide: 'API_URL', useFactory: getApiUrl },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    [AuthGuardService]
  ],
})
export class CcpRootModule { }

// ROTAS API

export function getApiUrl() {
  if (environment.production) {
    // PRODUCAO 10.131.60.191 
    return 'https://mcg.gerdau.net/webapi/api/';
  }
  else {
    // DESENVOLVIMENTO
    return 'https://mcg.gerdau.net/webapi/api/';
    //return 'http://localhost:5000/api/';
  }
}
