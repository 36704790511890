import { Injectable, ApplicationRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import notify from 'devextreme/ui/notify';
import { getPalette } from "devextreme/viz/palette";

@Injectable({
  providedIn: 'root'
})
export class Util {
  private mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(media: MediaMatcher, ref: ApplicationRef) {
    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this._mobileQueryListener = () => ref.tick();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  get isMobile(): boolean {
    return this.mobileQuery.matches;
  }

  paginarGrid(totalItens: number): number[] {
    let itens: number[] = new Array();
    if (totalItens > 0) {
      if (totalItens > 40) {
        itens.push(40);
        if (totalItens > 80) {
          itens.push(80);
          if (totalItens > 120) {
            itens.push(120);
          }
        }
      }
      else
        itens.push(totalItens);
    }
    return itens;
  }

  _popupAberto: boolean = false;
  get popupAberto(): boolean {
    return this._popupAberto;
  }

  set popupAberto(value) {
    this._popupAberto = value;
  }

  get alturaUtil(): number {
    return window.innerHeight - 60;
  }

  get larguraUtil(): number {
    return window.innerWidth - 36;
  }

  renderChart(chart) {
    if (chart)
      if (chart.instance)
        chart.instance.render();
  }

  get baseColors() {
    let palletes = <Array<any>>getPalette("Harmony Light").simpleSet;
    palletes.push(...<Array<any>>getPalette("Material").simpleSet);
    palletes.push(...<Array<any>>getPalette("Bright").simpleSet);
    palletes.push(...<Array<any>>getPalette("Dark Violet").simpleSet);

    return palletes;
  }

  getDateDiff(startDate: Date, endDate: Date) {
    var diff = endDate.getTime() - startDate.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));
    var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return { day: days, hour: hours, minute: minutes, second: seconds };
  }

  getDateDiffMinutes(startDate: Date, endDate: Date) {
    var miliseconds = endDate.getTime() - startDate.getTime();
    var seconds = miliseconds / 1000;
    var minutes = seconds / 60;

    return Math.abs(Math.round(minutes))
  }

  private showNotify(mensagem: string, type: string) {
    notify({
      position: { at: 'bottom right', my: 'bottom right', offset: '-12 -12' },
      message: mensagem,
      width: 'auto'
    }, type, 1200);
  }

  showError(error: any) {
    let mensagem: string;
    if (typeof error === 'string')
      mensagem = error;
    else {
      if (error.error.message)
        mensagem = error.error.message;
      else if (error.error && typeof error.error === 'string')
        mensagem = error.error;
      else
        mensagem = "Falha ao executar sua ação."

      //if (error.error instanceof ErrorEvent) {
      //  // client-side error
      //  errorMessage = `Error: ${error.error.message}`;
      //} else {
      //  // server-side error
      //  errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      //}
    }
    setTimeout(() => this.showNotify(mensagem, 'error'), 600);
  }

  showSuccess(mensagem: string) {
    setTimeout(() => this.showNotify(mensagem, 'success'), 600);
  }

  showInfo(mensagem: string) {
    setTimeout(() => this.showNotify(mensagem, 'info'), 600);
  }

  showWarning(mensagem: string) {
    setTimeout(() => this.showNotify(mensagem, 'warning'), 600);
  }

  showInfoSemRegistro() {
    this.showInfo('Nenhum registro encontrado.');
  }

  // TIPO DE EQUIPAMENTOS
  get tipoCaminhao(): number {
    return 1;
  }

  get tipoPanela(): number {
    return 2;
  }

  get equipamentoPequeno(): number {
    return 1;
  }

  get equipamentoGrande(): number {
    return 2;
  }

  get localParado(): number {
    return 7;
  }

  get localFornecedores(): number{
    return 4;
  }

  get panelaCheia(): number {
    return 1;
  }

  get panelaVazia(): number {
    return 2;
  }

  get movimentoEmProgresso(): number {
    return 1;
  }

  get movimentoCompleto(): number {
    return 2;
  }

  get movimentoEditado(): number {
    return 3;
  }

  get movimentoCancelado(): number {
    return 4;
  }
}
