import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'mcg-confirm-acao',
  templateUrl: './confirm-acao.component.html',
  styleUrls: ['./confirm-acao.component.css']
})
export class ConfirmAcaoComponent implements OnInit {

  @Input() mensagem: string;
  @Output() confirmar = new EventEmitter<boolean>();

  @Input() showConfirm: boolean = false;
  titleMessage: string;
  constructor() { }

  ngOnInit() {
  
  }

  cancelar() {
    this.confirmar.emit(false);
    this.showConfirm = false;
  }

  confirmarAcao() {
    this.confirmar.emit(true);
    this.showConfirm = false;
  }

}
