import { Injectable } from '@angular/core';
import { Router, CanActivate, CanActivateChild, CanLoad, ActivatedRouteSnapshot, 
         RouterStateSnapshot, Route, UrlSegment } from '@angular/router';
import { AutenticacaoService } from 'src/app/comuns/services/autenticacao.service';

@Injectable({ providedIn: 'root' })
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private router: Router,
    private authenticationService: AutenticacaoService
  ) { }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const path = segments.reduce((path, currentSegment) => {
      return path + '/' + currentSegment.path;
    }, '');
    return true;
  }

  canActivate(activatedRouter: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    let recurso = activatedRouter.data.recurso as string;

    let isLoggedIn = this.checkAuthentication(activatedRouter.routeConfig.path);

    if (isLoggedIn)
      return this.checkPermissions(recurso);
    else
      return false;
  }

  canActivateChild(activatedRouter: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean {
    return this.canActivate(activatedRouter, routerState);
  }

  checkAuthentication(path: string): boolean {
    const isLoggedIn = this.authenticationService.isLoggedIn();

    //Armazena url pra redirecionar após o login
    this.authenticationService.setRedirectUrl(path);

    if (!isLoggedIn)
      this.router.navigate(['/login']);
    else
      return isLoggedIn;
  }

  checkPermissions(recurso: string): boolean {
    let permissoesUsuario = this.authenticationService.validaRecursoUsuario(recurso);

    if (!permissoesUsuario)
      this.router.navigate(['/unauthorized']);
    else
      return true;
  }

}
