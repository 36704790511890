import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BarraFerramentasComponent } from './barra-ferramentas/barra-ferramentas.component';
import { BarraLoadingComponent } from './barra-loading/barra-loading.component';
import { SpinnerLoadingComponent } from './spinner-loading/spinner-loading.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmAcaoComponent } from './confirm-acao/confirm-acao.component';
import { MatCardModule } from '@angular/material/card';
import { DxPopupModule } from 'devextreme-angular';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatTooltipModule,
    MatCardModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatOptionModule,
    MatCheckboxModule,
    MatIconModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    DxPopupModule
  ],
  declarations: [
    BarraFerramentasComponent, BarraLoadingComponent, SpinnerLoadingComponent, ConfirmDeleteComponent, ConfirmAcaoComponent
  ],
  exports: [
    BarraFerramentasComponent, BarraLoadingComponent, SpinnerLoadingComponent, ConfirmDeleteComponent, ConfirmAcaoComponent
  ],
  providers: [

  ]
})
export class MCGComponentsModule {

}
