<mat-toolbar color="primary" class="toolbar" [class.is-mobile]="util.isMobile">
  <mat-toolbar-row>
    <div class="logo-site">
      <h1><a (click)="navigateHome()" >{{title | uppercase}}</a></h1>
    </div>
    <button *ngIf="authenticationService.isLoggedIn() && globalService.itensMenu.length > 0 && globalService.showAcoes()" type="button" mat-button class="button-xs" (click)="clickButton()">
      <fa-icon class="white" [icon]="['fas', 'bars']"></fa-icon>
    </button>
    <div class="toolbar-spacer">
      <div fxHide.xs>
        <h3 *ngIf="globalService.showAcoes()" >{{ title | uppercase}}</h3>
        <h3 *ngIf="!globalService.showAcoes()" >MCG - MOVIMENTAÇÕES</h3>
      </div>
    </div>
    <button *ngIf="authenticationService.isLoggedIn() && globalService.showAcoes()" type="button" mat-button [matMenuTriggerFor]="menu" class="button-xs" matTooltip="{{ authenticationService.getUsuarioLogado()?.nome }}">
      <fa-icon class="white" [icon]="['fas', 'user-shield']"></fa-icon>
      <!--<span>{{ authenticationService.getUsuarioLogado().NomeUsuario }}</span>-->
    </button>
    <!--<button *ngIf="!authenticationService.isLoggedIn()" type="button" mat-button (click)="login()" class="button-xs">
      <fa-icon [icon]="['fas', 'user-shield']"></fa-icon>
      <span>Login</span>
    </button>-->
    <mat-menu #menu="matMenu" class="menu" xPosition="before">
      <span mat-menu-item>{{ authenticationService.getUsuarioLogado()?.nome }}</span>
      <hr />
      <button mat-menu-item (click)="logout()">
        <fa-icon [icon]="['fas', 'user-lock']"></fa-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
